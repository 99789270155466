import React from 'react'
import ProjectListItem from '../projects/list-item'

export const FeaturedProjectsSlice = ({ slice }) => {
    const itemsData = slice.items;
  
    if (itemsData) {
        return (
            <div className="c-project-listing">
                <div className="container-fluid">
                    <div className="row">
                        <div className="c-project-listing__header">
                            <h2 className="c-project-listing__header-title">Selected work</h2>
                        </div>
                    </div>
                </div>
                <div className="c-project-listing__items">
                    <div className="container-fluid">
                        <div className="row">
                            {itemsData.map((article, i) => {
                                if (article) {
                                    return (
                                        <ProjectListItem key={i} article={article.featured_project.document} cssClass={article.featured_project_layout} index={i} />
                                    )
                                }
                                else {
                                    return null
                                }
                            })}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    
    return null
}