import React from 'react'

export const MediaSlice = ({ slice }) => {
    const primaryData = slice.primary;

    if (primaryData.mediaimage.url || primaryData.mediavideo.url) {
        const layout = primaryData.medialayout;
        return (
            <div className={`c-media js-text-visibility ${layout === "75% left" ? "c-media--left" : layout === "75% centre" ? "c-media--center" : layout === "75% right" ? "c-media--right" : ""}`}>
                <div className="c-media__media-container js-media-visibility">
                    <div className="c-media__media-wrapper">
                        {primaryData.mediaimage.url ? 
                        <picture>
                            <source srcSet={`${primaryData.mediaimage.url}&w=3500&fit=max`} media="(min-width: 2000px)" />
                            <source srcSet={`${primaryData.mediaimage.url}&w=3000&fit=max`} media="(min-width: 1500px)" />
                            <source srcSet={`${primaryData.mediaimage.url}&w=2500&fit=max`} media="(min-width: 1000px)" />
                            <source srcSet={`${primaryData.mediaimage.url}&w=1500&fit=max`} media="(min-width: 768px)" />
                            <source srcSet={`${primaryData.mediaimage.url}&w=1000&fit=max`} media="(min-width: 500px)" />
                            <img src={`${primaryData.mediaimage.url}&w=700&fit=max`} alt="" className="c-media__media" loading="lazy" width={primaryData.mediaimage.dimensions.width} height={primaryData.mediaimage.dimensions.height} />
                        </picture>
                        : null}
                        {primaryData.mediavideo.url ? 
                        <video autoPlay muted loop playsInline src={`${primaryData.mediavideo.url}#t=0.1`} className="c-media__media" loading="lazy"></video>
                        : null}
                    </div>
                </div>
            </div>
        )
    }

    return null
}