import React from 'react'
import { RichText } from 'prismic-reactjs'
import CustomLink from '../../utils/prismic-content-link'

export const QuoteSlice = ({ slice }) => {
    const primaryData = slice.primary;

    if (primaryData.quote.richText) {
        return (
            <blockquote className="c-blockquote js-text-visibility">
                <div className="container-fluid">
                    <div className="row">
                        <div className="c-blockquote__container">
                            {primaryData.quote.richText ?
                                <RichText render={primaryData.quote.richText} serializeHyperlink={CustomLink} /> : null}
                            {primaryData.quote_author.text ?
                                <footer className="c-blockquote__author">
                                    <p className="c-blockquote__author-name">{primaryData.quote_author.text}</p>
                                </footer> : null}
                        </div>
                    </div>
                </div>
            </blockquote>
        )
    }

    return null
}