import React from 'react'
import { RichText } from 'prismic-reactjs'
import CustomLink from '../../utils/prismic-content-link'

export const ContentMediaSlice = ({ slice }) => {
    const primaryData = slice.primary;

    if ((primaryData.contentmedia_content.richText && primaryData.contentmedia_image.url) || (primaryData.contentmedia_content.richText && primaryData.contentmedia_video.url)) {
        return (
            <div className={`c-content-media js-text-visibility ${primaryData.contentmedia_image_alignment === "Left" ? "c-content-media--invert" : ""}`}>
                <div className="container-fluid">
                    <div className="row">
                        <div className="c-content-media__content-container cms-content">
                            {primaryData.contentmedia_content.richText ?
                                <RichText render={primaryData.contentmedia_content.richText} serializeHyperlink={CustomLink} /> : null}
                        </div>
                        <div className="c-content-media__media-container js-media-visibility">
                            <div className="c-content-media__media-wrapper">
                                {primaryData.contentmedia_image.url ? 
                                <picture>
                                    <source srcSet={`${primaryData.contentmedia_image.url}&w=2000&fit=max`} media="(min-width: 2000px)" />
                                    <source srcSet={`${primaryData.contentmedia_image.url}&w=1500&fit=max`} media="(min-width: 1500px)" />
                                    <source srcSet={`${primaryData.contentmedia_image.url}&w=1000&fit=max`} media="(min-width: 500px)" />
                                    <img src={`${primaryData.contentmedia_image.url}&w=700&fit=max`} alt="" className="c-content-media__media" loading="lazy" width={primaryData.contentmedia_image.dimensions.width} height={primaryData.contentmedia_image.dimensions.height} />
                                </picture>
                                : null}
                                {primaryData.contentmedia_video.url ? 
                                <video autoPlay muted loop playsInline src={`${primaryData.contentmedia_video.url}#t=0.1`} className="c-content-media__media" loading="lazy"></video>
                                : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return null
}