import React from 'react'
import { Link } from 'gatsby'

const ProjectListItem = ({ article, cssClass, index }) => {
    var matches = cssClass.match(/\[(.*?)\]/);

    if (matches) {
        var submatch = matches[1];
    }

    if (article) {
        const loading = (index === 0 ? "eager" : "lazy");

        return (
            <div className={`c-project-listing__item c-project-listing__item--layout-${submatch}`}>
                <Link className="c-project-listing__item-link" to={article.url}>
                    <div className="c-project-listing__item-media-container js-media-visibility">
                        <div className="c-project-listing__item-media-wrapper">
                            {article.data.video.url ? 
                            <video autoPlay muted loop playsInline src={`${article.data.video.url}#t=0.1`} className="c-project-listing__item-media" loading={loading}></video>
                            : <>
                                {article.data.image.url ? 
                                    <picture>
                                        {submatch === "1" ?
                                            <>
                                                <source srcSet={`${article.data.image.url}&w=3500&fit=max`} media="(min-width: 2000px)" />
                                                <source srcSet={`${article.data.image.url}&w=3000&fit=max`} media="(min-width: 1500px)" />
                                                <source srcSet={`${article.data.image.url}&w=2500&fit=max`} media="(min-width: 1000px)" />
                                            </>
                                            : null
                                        }
                                        <source srcSet={`${article.data.image.url}&w=1500&fit=max`} media="(min-width: 768px)" />
                                        <source srcSet={`${article.data.image.url}&w=1000&fit=max`} media="(min-width: 500px)" />
                                        <img src={`${article.data.image.url}&w=700&fit=max`} alt="" className="c-project-listing__item-media" loading={loading} width={article.data.image.dimensions.width} height={article.data.image.dimensions.height} />
                                    </picture>
                                    : null
                                }
                            </>
                            }
                        </div>
                    </div>
                    <div className="c-project-listing__item-media-container js-media-visibility">
                        <div className="c-project-listing__item-media-wrapper">
                            {article.data.video2.url ? 
                            <video autoPlay muted loop playsInline src={`${article.data.video2.url}#t=0.1`} className="c-project-listing__item-media" loading={loading}></video>
                            : <>
                                {article.data.image2.url ? 
                                    <picture>
                                        {submatch === "2" || submatch === "4" ?
                                            <>
                                                <source srcSet={`${article.data.image2.url}&w=3500&fit=max`} media="(min-width: 2000px)" />
                                                <source srcSet={`${article.data.image2.url}&w=3000&fit=max`} media="(min-width: 1500px)" />
                                                <source srcSet={`${article.data.image2.url}&w=2500&fit=max`} media="(min-width: 1000px)" />
                                            </>
                                            : null
                                        }
                                        <source srcSet={`${article.data.image2.url}&w=1500&fit=max`} media="(min-width: 768px)" />
                                        <source srcSet={`${article.data.image2.url}&w=1000&fit=max`} media="(min-width: 500px)" />
                                        <img src={`${article.data.image2.url}&w=700&fit=max`} alt="" className="c-project-listing__item-media" loading={loading} width={article.data.image2.dimensions.width} height={article.data.image2.dimensions.height} />
                                    </picture>
                                    : null
                                }
                            </>
                            }
                        </div>
                    </div>
                    <div className="c-project-listing__item-content-container js-text-visibility">
                        <h3 className="c-project-listing__item-title">{article.data.title.text}</h3>
                        <p className="c-project-listing__item-summary">{article.data.summary.text}</p>
                    </div>
                </Link>
            </div>
        )
    }

    return null
}

export default ProjectListItem