import React from 'react'

export const MediaDoubleSlice = ({ slice }) => {
    const primaryData = slice.primary;

    if ((primaryData.mediadoubleimage.url && primaryData.mediadoubleimage2.url) || (primaryData.mediadoublevideo.url && primaryData.mediadoubleimage2.url)
        || (primaryData.mediadoubleimage.url && primaryData.mediadoublevideo2.url) || (primaryData.mediadoublevideo.url && primaryData.mediadoublevideo2.url)) {
        return (
            <div className={`c-media-double js-text-visibility ${primaryData.mediadoubleimagesmall === "Right" ? "c-media-double--invert" : ""}`}>
                <div className="container-fluid">
                    <div className="row">
                        <div className="c-media-double__container js-media-visibility ">
                            <div className="c-media-double__wrapper">
                                {primaryData.mediadoubleimage.url ? 
                                <picture>
                                    <source srcSet={`${primaryData.mediadoubleimage.url}&w=2000&fit=max`} media="(min-width: 2000px)" />
                                    <source srcSet={`${primaryData.mediadoubleimage.url}&w=1500&fit=max`} media="(min-width: 1500px)" />
                                    <source srcSet={`${primaryData.mediadoubleimage.url}&w=1000&fit=max`} media="(min-width: 500px)" />
                                    <img src={`${primaryData.mediadoubleimage.url}&w=700&fit=max`} alt="" className="c-media-double__media" loading="lazy" width={primaryData.mediadoubleimage.dimensions.width} height={primaryData.mediadoubleimage.dimensions.height} />
                                </picture>
                                : null}
                                {primaryData.mediadoublevideo.url ? 
                                <video autoPlay muted loop playsInline src={`${primaryData.mediadoublevideo.url}#t=0.1`} className="c-media-double__media" loading="lazy"></video>
                                : null}
                            </div>
                        </div>
                        <div className="c-media-double__container js-media-visibility ">
                            <div className="c-media-double__wrapper">
                                {primaryData.mediadoubleimage2.url ? 
                                <picture>
                                    <source srcSet={`${primaryData.mediadoubleimage2.url}&w=2000&fit=max`} media="(min-width: 2000px)" />
                                    <source srcSet={`${primaryData.mediadoubleimage2.url}&w=1500&fit=max`} media="(min-width: 1500px)" />
                                    <source srcSet={`${primaryData.mediadoubleimage2.url}&w=1000&fit=max`} media="(min-width: 500px)" />
                                    <img src={`${primaryData.mediadoubleimage2.url}&w=700&fit=max`} alt="" className="c-media-double__media" loading="lazy" width={primaryData.mediadoubleimage2.dimensions.width} height={primaryData.mediadoubleimage2.dimensions.height} />
                                </picture>
                                : null}
                                {primaryData.mediadoublevideo2.url ? 
                                <video autoPlay muted loop playsInline src={`${primaryData.mediadoublevideo2.url}#t=0.1`} className="c-media-double__media" loading="lazy"></video>
                                : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return null
}