import React from 'react'
import { ContentMediaSlice } from './content-media'
import { FeaturedProjectsSlice } from './projects-featured'
import { MediaSlice } from './media'
import { MediaDoubleSlice } from './media-double'
import { QuoteSlice } from './quote'

export const SliceZones = ({ slices }) => {
  if (slices === undefined) {
    return null;
  }

  const sliceComponents = {
    content___media: ContentMediaSlice,
    featured_projects: FeaturedProjectsSlice,
    media: MediaSlice,
    media_double: MediaDoubleSlice,
    quote: QuoteSlice,
  }

  return slices.map((slice, index) => {
    const SliceComponent = sliceComponents[slice.slice_type]
    
    if (SliceComponent) {
      return <SliceComponent slice={slice} key={`slice-${index}`} />
    }

    return null
  })
}